import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { map } from 'jquery';
imports: [HttpClientModule]

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private api = 'https://mailthis.to/photonlux'

  constructor(private http: HttpClient) { }

  PostDoForm(input: any){
    console.log("Enviandooo")
    return this.http.post(this.api, input, {responseType: 'text'}).pipe(
      
        (response)=>{
          return response;
        },
        (error: any) => {
          return error;
        }
      
    )
  }


}
